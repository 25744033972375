<script>
import {VclCode} from 'vue-content-loading';
import {mapState} from "vuex";

export default {
	components: {
		VclCode
	},
	props: {},
	computed: {},
	methods: {
		handleError: function (error) {
			console.log(`[ERROR] ${error}`);
			this.error = true;
		},
		getData: function () {
			fetch(process.env.VUE_APP_ROOT_API + 'v1/dashboard/products', {credentials: 'include'})
					.then(response => {
						if (response.ok) {
							return response.json();
						} else {
							throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
						}
					})
					.then(data => {
						this.data = data;
						this.ready = true;
					})
					.catch(error => {
						this.handleError(error);
					});
		},
		randomColor() {
			return '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
		},
		derivedColor(value) {
			return '#' + (value * 0xFFFFFF << 0).toString(16);
		},
	},
	created() {
		this.getData()
		this.timer = setInterval(() => {
			this.getData();
		}, 1000 * 60 * 5);
	},
	destroyed() {
		if (this.timer) clearInterval(this.timer);
	},
	data() {
		return {
			ready: false,
			error: false,
			data: {},
			timer: null
		}
	}
};
</script>

<template>
	<div v-if="ready">
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<div class="d-flex flex-wrap">
							<div class="me-2">
								<h5 class="card-title mb-3">Subscriptions</h5>
							</div>
						</div>
						<div class="row text-center">
							<div class="col-2">
								<div class="mt-3">
									<p class="text-muted mb-1">Total</p>
									<h5 class="mt-2">
										{{ $n(data.overall.total) }}
									</h5>
								</div>
							</div>

							<div class="col-2">
								<div class="mt-3">
									<p class="text-muted mb-1">Paid</p>
									<h5 class="mt-2">
										{{ $n(data.overall.count) }}
									</h5>
								</div>
							</div>

							<div class="col-2">
								<div class="mt-3">
									<p class="text-muted mb-1">Cancelled</p>
									<h5 class="mt-2">
										{{ $n(data.overall.cancelled+data.overall.expired) }}
										<div class="small text-muted">
											{{ $n(data.overall.cancelled) }} + {{ $n(data.overall.expired) }}
										</div>
									</h5>
								</div>
							</div>

							<div class="col-3">
								<div class="mt-3">
									<p class="text-muted mb-1">Gain</p>
									<h5>
										<div>
											{{ $n(data.overall.gain, {locale: 'de'}) }}
										</div>
										<template v-if="data.overall.direction === 1">
                      <span class="text-success font-size-13">
                        {{ $n(data.overall.trend, {locale: 'de'}) }} %
                        <i class="mdi mdi-arrow-up ms-1"></i>
	                      •
	                      <i class="fad fa-tally"/> {{ data.overall.previous }}
                      </span>
										</template>
										<template v-else-if="data.overall.direction === -1">
                      <span class="text-danger font-size-13">
                        {{ $n(data.overall.trend, {locale: 'de'}) }} %
                        <i class="mdi mdi-arrow-down ms-1"></i>
												•
	                      <i class="fad fa-tally"/> {{ data.overall.previous }}
                      </span>
										</template>
										<template v-else>
                      <span class="text-warning font-size-13">
                        +/- 0 %
                      </span>
										</template>
									</h5>
								</div>
							</div>

							<div class="col-3">
								<div class="mt-3">
									<p class="text-muted mb-1">Free</p>
									<h5>
										<div>
											{{ $n(data.free.count) }}
										</div>
										<small>
											{{ $n(data.free.percentage) }} % of {{ $n(data.overall.total) }}
										</small>
									</h5>
								</div>
							</div>
						</div>
						<hr/>
						<div>
							<h5 class="card-title mb-3">Products</h5>
							<ul class="list-group list-group-flush">
								<li class="list-group-item" v-for="product in data.products" :key="product.id">
									<div class="py-2">
										<h5 class="font-size-14">
											{{ product.title }}
											<span class="small float-end">
                        {{ product.count }}, {{ $n(product.percentage, {locale: 'de'}) }} %
                      </span>
										</h5>
										<div class="progress animated-progess progress-sm">
											<div
													class="progress-bar"
													role="progressbar"
													:style="'width: '+product.percentage+'%; background: '+derivedColor(product.color_seed)+';'"
													:aria-valuenow="product.percentage"
													aria-valuemin="0"
													aria-valuemax="100"
											></div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="error">
		<div class="row">
			<div class="col-lg-12 justify-content-start">
				<div class="card border border-danger">
					<div class="card-header bg-transparent border-danger">
						<h5 class="my-0 text-danger">
							<i class="far fa-exclamation-circle mr-3"></i> {{ $t('error.server.generic.title') }}
						</h5>
					</div>
					<div class="card-body">
						<h5 class="card-title mt-0">
							{{ $t('dashboard.error.info') }}
						</h5>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<div class="row">
			<div class="col-lg-12 justify-content-start">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<vcl-code :height="90"></vcl-code>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
